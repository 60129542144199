<template>

    <transition>

      <router-view v-slot="{ Component }">
        <navigation v-if="$route.name!=='Main'"/>
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
        <footerSection v-if="$route.name!=='Main'"/>
      </router-view>

    </transition>

</template>
<script>
import navigation from "@/components/navigation.vue";
import footerSection from "@/components/footer.vue";

export default {
  name: "App",
  components: {
    navigation,
    footerSection,
  },
  beforeCreate() {
    if(this.$route.params.domain) {
      this.$store.dispatch('loadNotification', {domain: this.$route.params.domain});
      this.$store.dispatch('loadProject', {domain: this.$route.params.domain});
      this.$store.dispatch('loadPlans', {domain: this.$route.params.domain});
    }else{
      // this.$store.dispatch('loadProjects');
    }
  }

}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&display=swap");
:root {
  --mainColor:#2A5A70;
  --darkGrey:#4E4E4F;
  --blueHover:#0056b3;
}
* {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html{
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 1.3;
}
#app {
  
  color: var(--mainColor);
}
body {
  background: #F2F5F9;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body::before{
  content:"";
  position:fixed;
  left:0;top:0;
  background: url("assets/images/pattern.png");
  opacity:0.40;
  mix-blend-mode: multiply;
  width:100%;
  height:100%;
}
h1 {
  font-family: 'Lexend', serif;
  font-weight: bold;
  /* font-size: 2.3rem; */
  width: 100%;
  line-height: 1.3;
  margin-bottom: 11px;
}
h2 {
  font-family: 'Lexend', sans-serif;
  font-weight: 600;
  /* font-size: 1.5rem; */
  line-height: 1.5;
  color: var(--mainColor);
}
a {
  text-decoration: none !important;
  color: inherit;
}
a:hover {
  color: inherit;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
/*Bootstrap Fixes*/
@media (min-width: 1300px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 90vw;
        margin: auto;
    }
}




/*------------- Button UI -------------------*/
.cta{
  font-weight: 700;
  color:var(--mainColor);
  margin-top:8px;
  padding: 0.7rem 2rem 0.7rem 1rem;
  display: inline-block;
  border:0;
  border-radius: 100px;
  transition: all 0.2s;
  position: relative;
  background-color: #fff;
  margin-right:20px;
}
.cta span.icn{
  border-radius:50%;
  margin-right:20px;
}
.cta span.icn img{
  max-width: 35px;
  height:auto;
}
.cta span.txt{
  font-size:1.2rem;
  vertical-align: middle;
}
/*------------- Button UI end -------------------*/

/*---------------- Button animation start --------------*/

.cta:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color:  var(--blueHover)   !important;
}
.cta::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.7s;
  background-color: #fff;
  opacity:0.6;
}
.cta:hover::after {
  transform: scaleX(1.2) scaleY(1.4);
  opacity: 0;
}
</style>
