import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/resposive.css';
import './assets/css/animista.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store';
import circles from './components/circles'
import BackButton from './components/general/backButton'
import Modal from './components/general/modal'

const Vue =createApp(App);

Vue.component('circles',circles)
Vue.component('BackButton',BackButton)
Vue.component('modal',Modal)

Vue.use(VueAxios,axios)
Vue.use(store)
Vue.use(router).mount('#app')

