import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  // {
  //   path: '/:domain',
  //   name: 'Home',
  //   component: () => import('../views/Home.vue')
  // },
  {
    path: '/masterplan',
    name: 'Masterplan',
    component: () => import('../views/masterplan.vue')
  },
  {
    path: '/plan/:id',
    name: 'plan',
    component: () => import('../views/plan.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
