import vuex from 'vuex';
import axios from 'axios';

let store = new vuex.Store({
    // API_URL:'https://pimula.agency/MVAdmin'
    // API_URL:'http://127.0.0.1:8000'
    state:{
        API_URL:'https://pimula.agency/MVAdmin',
        projects:'',
        subdomain:'',
        project:[],
        notification:[],
        masterplan:[],
        plans:[],
        plan:['test']

    },
    mutations:{
        SET_PROJECTS (state,data){
            state.projects = data
        },
        SET_PROJECT (state,data){
            state.project = data
        },
        SET_NOTIFICATION (state,data){
            state.notification = data
        },
        SET_MASTERPLAN (state,data){
            state.masterplan = data
        },
        SET_PLANS (state,data){
            state.plans = data
        },
        SET_PLAN (state,data){
            state.plan = data
        }
    }
    ,
    actions:{
        // async loadProjects({ commit }){
        //     axios.get(this.state.API_URL+'/api/projects/')
        //         .then(data => {
        //             // console.log(data);
        //             commit('SET_PROJECTS',data.data)
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         })
        // },
        async loadProject({ commit },{domain}) {
            // console.log('domain: '+domain)
            axios.get(this.state.API_URL+'/api/projects/'+domain)
                .then(data =>{
                    commit('SET_PROJECT',data.data)
                })
                .catch(error =>{
                    console.log(error)
                })
        },
        async loadNotification({ commit },{domain}){
            axios.get(this.state.API_URL+'/api/'+domain+'/notification')
                .then(data=>{
                    commit('SET_NOTIFICATION',data.data)
                })
                .catch(error =>{
                    console.log(error)
                })
        },
        async loadMasterplan({ commit },{domain}){
            axios.get(this.state.API_URL+'/api/'+domain+'/masterplan')
                .then(data=>{
                    commit('SET_MASTERPLAN',data.data)
                })
                .catch(error =>{
                    console.log(error)
                })
        },
        async loadPlans({ commit },{domain}){
            axios.get(this.state.API_URL+'/api/'+domain+'/plans')
                .then(data=>{
                    commit('SET_PLANS',data.data)
                })
                .catch(error =>{
                    console.log(error)
                })
        },
    },
    getters:{
        getImage:(state)=>(name,folder)=>{
            if(name) {
                return state.API_URL + '/storage/images/'+folder+ '/' +name;
            }
        },
        getPlan:(state)=>(name)=>{
            return state.plans.find(({slug}) => slug === name);
        }
    }
})
export default store;
