<template>
    <router-link to="../masterplan" exact-path class="backBtn">
        <span class="icn"><img src="@/assets/images/icons/arrow.svg"/></span>
        <span class="txt">
        Back to Masterplan
        </span>
    </router-link>
</template>

<script>
export default {
    name:"backButton",
}
</script>
<style scoped>
    .backBtn{
        display:block;
        position:relative;
        margin-bottom:2rem;
        cursor: pointer;
        transition: all 0.8s ease;
        font-size: 1.3em;
    }
    .backBtn:hover{
        transform: translate(-10px);
    }
    .backBtn:hover .icn img{
        background-color:white;
        border-radius:50px;
    }
    .backBtn .icn img{
        height:1.6em;
        width:auto;
    }
</style>