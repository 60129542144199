<template>
    <div>
    <span class="dot circle1" :style="'left:'+leftPos1+';top:'+topPos1"></span>
    <span class="dot circle2" :style="'left:'+leftPos2+';top:'+topPos2"></span>
    </div>
</template>
<script>
export default {
    name:"circles",
    props:['leftPos1','leftPos2','topPos1','topPos2']
}
</script>
<style scoped>
    div{width:100vw;height: 100%; overflow: hidden !important;}
    .dot{
        position:fixed;
        background-color:#e0ebf2;
        border-radius: 50%;
        z-index: -9999;
        transition:all 1s ease;
    }
    .circle1{
        height:120vh;
        width:120vw;
        left:-70vw;
        top:-20vh;
        opacity:0.3;
        mix-blend-mode: multiply;
    }
    .circle2{
        height:150vh;
        width:150vw;
        right:-70vw;
        bottom:-50vh;
        opacity:1;
    }
    @media (max-width: 800px){

        .dot{
            max-height: 100%;
            top:0;
        }
    }
</style>