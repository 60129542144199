<template>

  <footer class="container">
          <h2 class="float-left" v-html="this.$store.state.project.footer">
          </h2>
          <h2 class="float-right">
            © 2020-2021
          </h2>
  </footer>
</template>

<script>
export default {
    name: 'FooterSection',
}
</script>

<style>
footer{
  position:absolute;
  right:0;
  bottom:4vh;
  left:0;
  
}
footer h2{
  color:var(--darkGrey);
  font-size:1.05rem;
  font-weight: 400;
}
footer a{
  color:var(--mainColor);
  font-weight: 600;
}
footer a:hover{
  color:var(--blueHover) !important;
}
</style>